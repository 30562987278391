// Function to convert data URL to Blob
const dataURLtoBlob = (dataurl) => {
    const arr = dataurl.split(',');
    if (arr.length !== 2) {
        return null; 
    }

    const mimeMatch = arr[0].match(/:(.*?);/);
    if (!mimeMatch || !mimeMatch[1]) {
        return null; 
    }

    const mime = mimeMatch[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
};

function extractIds(url) {
    const regex = /\/([a-f0-9]{24})\/([a-f0-9]{24})\/image/;
    const match = url.match(regex);

    if (match) {
        const farmId = match[1];
        const templateId = match[2];
        return { farmId, templateId };
    } else {
        console.error("The URL does not match the expected structure.");
        return null;
    }
}
export default {extractIds,dataURLtoBlob};