import {useState, useEffect} from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Tooltip } from '@blueprintjs/core';
import { globalStore } from './globalStore';
import logger  from '../logger';
import { handleSelectTemplate } from '../components/templates';

export const TemplateSaveButton = observer(({store}) => {
    const [isLoading, setIsLoading] = useState(false);

    const saveTemplate = async () => {
        setIsLoading(true);
        try {
            const dataURL = await store.toDataURL({ pixelRatio: 0.5, mimeType: 'image/jpeg' });
    
            const data = {
                ...globalStore.template,
                content: store.toJSON(),
                imageData: dataURL,
            };
    
            const res = await fetch(`${globalStore.serverUrl}/cloudtemplates`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...(globalStore.apiKey ? { "x-api-key": globalStore.apiKey } : {}),
                },
                body: JSON.stringify(data),
            });
    
            if (res.ok) {
                const responseData = await res.json(); 
                const templateId = responseData._id;
                globalStore.contentChanged = false;
                await handleSelectTemplate(store, {}, `${globalStore.serverUrl}/cloudtemplates/${templateId}`);
                await globalStore.loadTemplates();
                logger.logAction("Template was Saved",{action:"save",entity:"template"});
            }
        } catch (error) {
            console.error("Failed to save template:", error);
            logger.logActionError("Exception occurred - Template was not Saved", { error: error.message,action:"save",entity:"template"});
        } finally {
            setIsLoading(false);
        }
    };
    

        // Update iconType based on contentChanged state
        useEffect(() => {
            globalStore.iconType = globalStore.contentChanged ? 'disabled' : 'default';
        }, [globalStore.contentChanged]);


    return(
        <Tooltip content={globalStore.disableSaveButton ? "Template limit reached! Upgrade your package to save more templates." : ""}>
        <Button
            intent='Primary'
            disabled={!globalStore.contentChanged || globalStore.disableSaveButton}
            loading={isLoading}
            onClick={saveTemplate}
        >
            &emsp; Save &emsp;
        </Button>
    </Tooltip>
    );
});