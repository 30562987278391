
import { useEffect, useState } from 'react';
import { NumericInput, Button, Dialog, DialogBody, DialogFooter, Radio, Checkbox } from '@blueprintjs/core';
import logger from '../../logger';

export const PageResizeDialog = (props) => {
    const [pageWidth, setPageWidth] = useState(0);
    const [pageHeight, setPageHeight] = useState(0);
    const [selectedOption, setSelectedOption] = useState('list'); // 'list' or 'custom'
    const [selectedPreset, setSelectedPreset] = useState(null);
    const [applyOnAllScreens, setApplyOnAllScreens] = useState(false);

    const handleApply = () => {
        if (props.store.activePage) {
            props.store.activePage.set({
                width: pageWidth,
                height: pageHeight
            });
            logger.logAction("Page was Resized", { action: "Resize Page", entity: "page", width: pageWidth, height: pageHeight,pageId: props.store?.activePage?.id ,pageName: props.store?.activePage?.custom?.pageName });
        }
    
        if (applyOnAllScreens) {
            props.store.setSize(pageWidth, pageHeight);
            logger.logAction("Pages were Resized", { action: "Resize All", entity: "page", width: pageWidth, height: pageHeight });
        }
    
        props.onClose();
    };
    

    useEffect(() => {
        const width = props.store.activePage.width === 'auto' ? props.store.width : props.store.activePage.width;
        const height = props.store.activePage.height === 'auto' ? props.store.height : props.store.activePage.height;

        setPageWidth(width);
        setPageHeight(height);
    }, []);

    const handlePresetChange = (event) => {
        const preset = presets.find(p => `${p.name} - ${p.width} X ${p.height} px` === event.target.value);
        if (preset) {
            setPageWidth(preset.width);
            setPageHeight(preset.height);
            setSelectedPreset(preset.name);
        }
    };

    return (
        <Dialog title="Resize Page" {...props}>
            <DialogBody className="flex flex-col">
                <div className="flex items-center space-x-4 mb-3">
                    <Radio
                        label="Select from list"
                        value="list"
                        checked={selectedOption === 'list'}
                        onChange={() => setSelectedOption('list')}
                    />
                    <Radio
                        label="Custom size"
                        value="custom"
                        checked={selectedOption === 'custom'}
                        onChange={() => setSelectedOption('custom')}
                    />
                </div>

                {selectedOption === 'list' ? (
                    <div className="mb-3">
                        <select
                            className="bp4-input w-full border p-2 hover:bg-gray-100"
                            style={{ border: '1px solid #ccc', backgroundColor: 'white' }}
                            onChange={handlePresetChange}
                            value={selectedPreset ? `${selectedPreset} - ${pageWidth} X ${pageHeight} px` : ''}
                        >
                            <option value="" disabled>Select...</option>
                            {presets.map((preset, index) => (
                                <option key={index} value={`${preset.name} - ${preset.width} X ${preset.height} px`}>
                                    {preset.name} - {preset.width} X {preset.height} px
                                </option>
                            ))}
                        </select>
                    </div>
                ) : (
                    <>
                        <div className="flex items-center mb-1">
                            <span className="w-14 mr-6 flex-grow-0 flex-shrink-0">Width</span>
                            <NumericInput
                                fill
                                value={pageWidth}
                                onValueChange={(value) => setPageWidth(value)}
                                rightElement={<span style={{ padding: '0 5px' }}>px</span>}
                            />
                        </div>
                        <div className="flex items-center mb-3">
                            <span className="w-14 mr-6 flex-grow-0 flex-shrink-0">Height</span>
                            <NumericInput
                                fill
                                value={pageHeight}
                                onValueChange={(value) => setPageHeight(value)}
                                rightElement={<span style={{ padding: '0 5px' }}>px</span>}
                            />
                        </div>
                    </>
                )}
                <div className="mt-4">
                <Checkbox
                    checked={applyOnAllScreens}
                    onChange={(e) => setApplyOnAllScreens(e.target.checked)}
                >
                    Apply on all screens
                </Checkbox>
                </div>
            </DialogBody>
            <DialogFooter
                actions={
                    <><Button text="Cancel" outlined={true} onClick={props.onClose} /><Button text="Apply" intent="primary" onClick={handleApply} /></>
                }
            >
            </DialogFooter>
        </Dialog>
    );
};

const presets = [
    { name: 'Full HD', width: 1920, height: 1080 },
    { name: 'Instagram Post', width: 1080, height: 1080 },
    { name: 'Instagram Story', width: 1080, height: 1920 },
    { name: 'Facebook Post', width: 940, height: 788 },
    { name: 'Facebook Ad', width: 1200, height: 628 },
    { name: 'Youtube Thumbnail', width: 1280, height: 720 },
    { name: 'Activation Screens', width: 1000, height: 600 }
];



