const CustomRadio = ({ 
    label, 
    value, 
    selectedValue, 
    onChange, 
    className = '', 
    labelClassName = '', // Add labelClassName prop
    defaultFilled = false 
}) => {
    const isSelected = selectedValue === value;
  
    const handleClick = () => {
        if (onChange && typeof onChange === 'function') {
          onChange(value);
        }
    };
  
    const shouldShowFilled = defaultFilled ? !isSelected : isSelected;
  
    return (
      <label className={`flex items-center cursor-pointer group ${className}`} onClick={handleClick}>
        <input
          type="radio"
          value={value}
          checked={isSelected}
          readOnly
          className="hidden"
        />
        <span className="inline-block w-6 h-6 mr-2">
          {shouldShowFilled ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="w-full h-full"
            >
              <circle
                cx="12"
                cy="12"
                r="10"
                className="fill-none stroke-blue-600 stroke-2"
              />
              <circle
                cx="12"
                cy="12"
                r="6"
                className="fill-blue-600"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="w-full h-full"
            >
              <circle
                cx="12"
                cy="12"
                r="10"
                className="fill-none stroke-gray-400 stroke-2 group-hover:stroke-blue-400 transition-colors"
              />
            </svg>
          )}
        </span>
        {/* Replace hardcoded classes with labelClassName */}
        <span className={`${labelClassName}`}>
          {label}
        </span>
      </label>
    );
};
  
export default CustomRadio;
