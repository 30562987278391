const DEFAULTS = {
    apiEndpoint: 'https://www.templatefarm.io/api',
    imageApiEndpoint: 'https://imageapi.templatefarm.io',
    apiKey: null,
    template: null,
    allowClose: true,
    saveEditButtonText: 'Save',
    fullScreen: false,
    generateTemplateURLOnSave : false,
    templateId: null,
    showAllTemplates: false,
}

window.TE = {
    mount: (selector, options) => {
        const root = document.querySelector(selector);
        const editor = document.createElement('te-designer');
        editor.options = Object.assign({}, DEFAULTS, options);
        editor.apiKey = editor.options?.apiKey;
        editor.template = editor.options?.template;
        editor.allowClose = editor.options?.allowClose;
        editor.saveEditButtonText = editor.options?.saveEditButtonText;
        editor.fullScreen = editor.options?.fullScreen;
        root.appendChild(editor);
    },
    popup: (options, callback) => {
        const root = document.body;
        const editor = document.createElement('te-designer-popup');
        editor.options = Object.assign({}, DEFAULTS, options);
        editor.apiKey = editor.options?.apiKey;
        editor.template = editor.options?.template;
        editor.allowClose = editor.options?.allowClose;
        editor.saveEditButtonText = editor.options?.saveEditButtonText;
        editor.fullScreen = editor.options?.fullScreen;
        editor.largeSizeResizeText = editor.options?.largeSizeResizeText;
        editor.generateTemplateURLOnSave = editor.options?.generateTemplateURLOnSave;
        editor.templateId = editor.options?.templateId;
        editor.showAllTemplates = editor.options?.showAllTemplates;
        editor.callback = callback;
          const styleElement = document.createElement('style');
          const normalSizeCssResizeText = `
              .go3960841851 .bp5-button-text span {
                  font-size: 0.8rem !important;
                  line-height: 1.5rem !important;
              }
          `;
          styleElement.textContent = normalSizeCssResizeText;
          document.head.appendChild(styleElement);
        root.appendChild(editor);
    }
}
