
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Tooltip } from '@blueprintjs/core';
import { globalStore } from './globalStore';
import { ContactUsDialog } from './dialogs/contactUsDialog';
import { PageResizeDialog } from './dialogs/pageResizeDialog';
import logger from '../logger';
import Workspace from 'polotno/canvas/workspace';
import '../styles/customWorkspace.css';


const PageControls = observer(({ store, page, width, height, xPadding, yPadding }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [pageLimit, setPageLimit] = useState(null);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isResizeDialogOpen, setIsResizeDialogOpen] = useState(false);
  const [resizeTooltipOpen, setResizeTooltipOpen] = useState(false);
  const [pageName, setPageName] = useState(
    page?.custom?.pageName || ""
  );

  const hasManyPages = store.pages.length > 1;
  const index = store.pages.indexOf(page);

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleContactUs = () => {
    window.location.href = 'https://engines365.infront.sport/contact-sales';
  };

  const checkPageLimit = async () => {
    try {
      const response = await fetch(`${globalStore.serverUrl}/cloudtemplates/checkPageLimit`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(globalStore.apiKey ? { 'x-api-key': globalStore.apiKey } : {}),
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      return data?.pageLimit ?? null;
    } catch (error) {
      console.error('Error fetching page limit:', error);
      return 5; // default value
    }
  };

  const handleDuplicate = async () => {
    const limit = await checkPageLimit();
    setPageLimit(limit);
    if (store.pages.length < limit || limit === -1) {
      page.clone();
      logger.logAction("Page was Duplicated", { action: "Duplicate", entity: "page" });
    } else {
      setIsDialogOpen(true);
    }
  };

    const getPreviousPageDimensions = (store) => {
      const previousPage = store.activePage;
      return {
          width: previousPage?.width === 'auto' ? 1080 : previousPage?.width,
          height: previousPage?.height === 'auto' ? 1080 : previousPage?.height,
          bleed: previousPage?.bleed || 0,
      };
  };

  const handleAddPage = async () => {
    const limit = await checkPageLimit();
    setPageLimit(limit);
    if (store.pages.length < limit || limit === -1) {
      const { width, height, bleed, custom } = getPreviousPageDimensions(store);
      const newPage = store.addPage({ width, height, bleed, custom });
      const currentIndex = store.pages.indexOf(page);
      newPage.setZIndex(currentIndex + 1);
      logger.logAction("Page was Added", { action: "Add", entity: "page" });
    } else {
      setIsDialogOpen(true);
    }
  };

  const handleDelete = () => {
    store.deletePages([page.id]);
    logger.logAction("Page was Deleted", { action: "Delete", entity: "page" });
  };

  const handleRenamePage = (e) => {
    setPageName(e.target.value);
  };

  const savePageName = () => {
    page.set({custom: { pageName: pageName }});
    setIsEditingName(false);
  };

  return (
    <>
      <div
         className="page-controls-container"
         style={{
           '--yPadding': `${yPadding}px`,
           '--xPadding': `${xPadding}px`,
         }}
      >
    <div className="page-name-container">
      {/* Page Number */}
      <span className="page-number">{`Page ${index + 1} - `}</span>
      {/* Page Name */}
      {isEditingName ? (
        <input
          type="text"
          value={pageName}
          onChange={handleRenamePage}
          onBlur={savePageName}
          onKeyDown={(e) => e.key === 'Enter' && savePageName()}
          autoFocus
          placeholder='Add page name'
          className="page-name-input"
          style={{ width: Math.max(100, pageName.length * 8) + 'px' }}
        />
      ) : (
        <span
          className="page-name-span"
          onClick={() => setIsEditingName(true)}
          title={pageName || 'Add page name'}
        >
          {pageName || 'Add page name'}
        </span>
      )}
        <span>
        {`${page.width === 'auto' ? 1080 : page.width} X ${page.height === 'auto' ? 1080 : page.height}`}
      </span>
    </div>
        {hasManyPages && (
          <Tooltip content="Move Up" disabled={index === 0}>
            <Button
              icon="chevron-up"
              minimal
              disabled={index === 0}
              onClick={() => {
                page.setZIndex(index - 1);
              }}
            />
          </Tooltip>
        )}
        
        {hasManyPages && (
          <Tooltip content="Move Down" disabled={index === store.pages.length - 1}>
            <Button
              icon="chevron-down"
              minimal
              disabled={index === store.pages.length - 1}
              onClick={() => {
                page.setZIndex(index + 1);
              }}
            />
          </Tooltip>
        )}
          <Tooltip content={'Page Resize'} isOpen={resizeTooltipOpen}>
            {globalStore.userRole === 'admin' &&                    
              <Button 
                icon="fullscreen"
                minimal 
                onClick={() => {
                  setIsResizeDialogOpen(true);
                  setResizeTooltipOpen(false); // Close tooltip when opening dialog
                }} 
                onMouseEnter={() => setResizeTooltipOpen(true)}
                onMouseLeave={() => setResizeTooltipOpen(false)}
              />
            }
      </Tooltip>  
        <Tooltip content="Duplicate Page">
          <Button
            icon="duplicate"
            minimal
            onClick={handleDuplicate}
          />
        </Tooltip>

        {hasManyPages && (
          <Tooltip content="Remove Page">
            <Button
              icon="trash"
              minimal
              onClick={handleDelete}
            />
          </Tooltip>
        )}
        
        <Tooltip content="Add Page">
          <Button
            icon="insert"
            minimal
            onClick={handleAddPage}
          />
        </Tooltip>

      </div>

      <ContactUsDialog
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        onContactUs={handleContactUs}
        limit={pageLimit}
        entityType="pages"
      />
      <PageResizeDialog store={store} isOpen={isResizeDialogOpen}   onClose={() => {setIsResizeDialogOpen(false); setResizeTooltipOpen(false);}} />
    </>
  );
});

const CustomWorkspace = ({ store, entityType = "pages" }) => {
  return (
    <Workspace
      store={store}
      components={{
        PageControls: (props) => <PageControls store={store} {...props} />
      }}
    />
  );
};

export default CustomWorkspace;

