import React from 'react';
import MdPhoto from '@meronex/icons/md/MdPhoto';
import { SectionTab } from 'polotno/side-panel';
import { Thumbnail } from './thumbnail';
import { getImageSize } from 'polotno/utils/image';
import { TemporeryFilesIcon } from '../components/icons'

export const TemporaryAssetsPanel = ({ tempAssets ,store }) => {

    const selectImage = async (assetUrl, assetName) => {
        try {
          const url = assetUrl;
          const element = store.selectedElements[0];
          let { width, height } = await getImageSize(url);
          let type = 'image'
    
          if (
            element &&
            element.type === 'image' &&
            !element.locked 
          ) {
            element.set({ src: url });
            return;
          }
    
          const scale = Math.min(store.width / width, store.height / height, 1);
          width = width * scale;
          height = height * scale;
    
          const x = store.width / 2 - width / 2;
          const y = store.height / 2 - height / 2;
    
          store.activePage?.addElement({
            type,
            src: url,
            x,
            y,
            width,
            height,
            name: assetName
          });
        } catch (error) {
          console.error('Error selecting image:', error);
        }
      };

  return (
    <div className='flex flex-col h-full'>
      <div className='flex items-center mb-1'>
      </div>
      <div className='grid grid-cols-2 gap-[4px] overflow-y-auto overflow-x-hidden'>
        {tempAssets.map((url, index) => (
          <Thumbnail 
            key={index} 
            src={url} 
            name={`image ${index + 1}`}
             onSelect={() => selectImage(url, `image ${index + 1}`)}
          />
        ))}
      </div>
    </div>
  );
};

export const TemporaryAssetsSection = {
    name: 'Temporary-assets',
    Tab: (props) => (
      <SectionTab name="Temp files" {...props}>
       <span className='bp5-icon bp5-icon-fullscreen'>
            <TemporeryFilesIcon height="22px" style={{height: '22px', width: '22px'}}/>
        </span>
      </SectionTab>
    ),
    Panel: ({ store, tempAssets }) => (
      <TemporaryAssetsPanel tempAssets={tempAssets} store={store} />
    ),
  };
  