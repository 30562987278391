import React from 'react';
import { Dialog, Classes, Button } from '@blueprintjs/core';

export const ContactUsDialog = ({ isOpen, onClose, onContactUs , limit, entityType }) => {
  return (
    <Dialog isOpen={isOpen} onClose={onClose} title="Contact Us">
      <div className={Classes.DIALOG_BODY}>
      {`Your current package limits you to ${limit} ${entityType}. 
        Upgrade now to create unlimited designs.
        Contact us to enhance your account and unleash your full potential!`}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={onClose}>Close</Button>
          <Button intent="primary" onClick={onContactUs}>Contact Us</Button>
        </div>
      </div>
    </Dialog>
  );
};