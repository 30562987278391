import {DatadogLogger} from 'infront-logger/browser';
import { globalStore } from './components/globalStore';

 let loggerOptions = {
      clientToken: 'pub24f4f12130c3f80f72342a8856fdd4ca',
       service : "TE_CLOUD_TEMPLATES",
         forwardErrorsToLogs : false
     }
let logger = new DatadogLogger(loggerOptions);

const logAction = (action, metadata = {}) => {
    logger.log(action, {
      farmName: globalStore?.farmName,
      farmId: globalStore?.farmId,
      userName: globalStore?.userName,
      userId: globalStore?.userId,
      template: globalStore?.template,
      ...metadata,
    });
  };

  const logActionError = (action, metadata = {}) => {
    logger.error(action, {
      farmName: globalStore?.farmName,
      farmId: globalStore?.farmId,
      userName: globalStore?.userName,
      userId: globalStore?.userId,
      template: globalStore?.template,
      ...metadata,
    });
  };
  

  export default {
    logAction,
    logActionError,
  };