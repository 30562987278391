import React from 'react';
import { observer } from 'mobx-react-lite';
import { InputGroup, Dialog, Classes, Button,Tooltip,Position  } from '@blueprintjs/core';
import { globalStore } from './globalStore';
import { Thumbnail } from './thumbnail';
import { CreateTemplateIcon,TemplateInfoIcon,CreateTemplateBlueIcon } from './icons';
import logger from '../logger';

import '../styles/templates.css';
import { ContactUsDialog } from '../components/dialogs/contactUsDialog';

export const TemplatesPanel = observer(({ store,entityType = "templates" }) => {
    const [templates, setTemplates] = React.useState([]);
    const [templatesLibrary, setTemplatesLibrary] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isSaving, setIsSaving] = React.useState(false);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [isChooseTemplateDialogOpen, setIsChooseTemplateDialogOpen] = React.useState(false);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [selectedTemplate, setSelectedTemplate] = React.useState(null);
    const [isCreateOwnTemplateClicked, setIsCreateOwnTemplateClicked] = React.useState(false);
    const [imageEditorLimit ,setImageEditorLimit] =  React.useState(5);

    const load = async (q) => {
        const response = await fetch(`${globalStore.serverUrl}/cloudtemplates${q ? '?q=' + q : ''}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                ...(globalStore.apiKey ? { "x-api-key": globalStore.apiKey } : {}),
            }
        });

        if (response.ok) {
            const data = await response.json();
            setTemplates(data);
        }

        setIsLoading(false);
    }

    globalStore.loadTemplates = load;

    const handleNew = async () => {
        // if (globalStore.contentChanged && store?.activePage && !window.confirm("All changes will be lost! Are you sure?")) return;
        const canCreateMoreTemplates = await checkTemplatesNumber();
        if (canCreateMoreTemplates) {
            setIsChooseTemplateDialogOpen(true);
            globalStore.disableSaveButton = false;
        } else {
            // globalStore.disableSaveButton = true; 
            setIsDialogOpen(true);
        }
    }

    const handleCreateNewTemplate = () => {
        store.clear();
        store.addPage();
        globalStore.template = { name: 'New Template' };
        globalStore.contentChanged = false;
        handleCloseChooseTemplateDialog();
        logger.logAction("Template was Added",{action:"Add",entity:"template"})
    }

    const loadTemplatesLibrary = async () => {
        const response = await fetch(`${globalStore.serverUrl}/cloudtemplates/library`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                ...(globalStore.apiKey ? { "x-api-key": globalStore.apiKey } : {}),
            }
        });

        if (response.ok) {
            const data = await response.json();
            setTemplatesLibrary(data);
        }
    }

    const handleSelect = (template) => {
        if (store?._activePageId && globalStore.contentChanged && !window.confirm("All changes will be lost! Are you sure?")) return;
        handleSelectTemplate(store, template, `${globalStore.serverUrl}/cloudtemplates/${template.id}`);
    }
    
    const handleLibrarySelect = async (template) => {
            await handleSelectTemplate(store,template, `${globalStore.serverUrl}/cloudtemplates/library/${template.id}`);
            await load();
    }

    const handleDelete = async (template) => {
        if (!window.confirm("Template will be permanently deleted. Are you sure?")) return;
    
        try {
            const res = await fetch(`${globalStore.serverUrl}/cloudtemplates/${template.id}`, {
                method: 'DELETE',
                headers: {
                    ...(globalStore.apiKey ? { "x-api-key": globalStore.apiKey } : {}),
                },
            });
    
            if (res.ok) {
                await load();
                store.clear();
                // store.addPage();
                globalStore.template = { name: 'New Template' };
                globalStore.contentChanged = false;
                logger.logAction("Template was deleted", { templateId: template.id ,action:"Delete",entity:"template"});
            } 
        } catch (error) {
            console.error("Exception occurred while deleting template:", error);
            logger.logActionError("Exception occurred - Template was not deleted", { templateId: template.id, error: error?.message,action:"Delete",entity:"template"});
        }
    };
    

    const checkTemplatesNumber = async () => {

        const response = await fetch(`${globalStore.serverUrl}/cloudtemplates/checkTemplatesNumber`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                ...(globalStore.apiKey ? { "x-api-key": globalStore.apiKey } : {}),
            }
        });
        const data = await response.json();
        setImageEditorLimit(data.imageEditorLimit)
        return data.canCreateMoreTemplates;
    }

    const checkSelected = (template) => {
        return globalStore.template?.id === template.id;
    }

    const getTemplateName = (template) => {
        return globalStore.template?.id === template.id ? globalStore.template.name : template.name;
    }

    React.useEffect(() => {
        load();
        loadTemplatesLibrary();
    }, []);

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleCloseChooseTemplateDialog = () => {
        setIsChooseTemplateDialogOpen(false);
    };

    const handleContactUs = () => {
        window.location.href = 'https://engines365.infront.sport/contact-sales';
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        load(e.target.value);
    };

    const handleCreateOwnTemplateClick = () => {
        setIsCreateOwnTemplateClicked(true);
        setSelectedTemplate(null);
    }

    return (
        <div className='templates-panel-dialog-layout'>
            <div className='flex flex-col mb-1'>
                {globalStore.userRole === 'admin' &&
                    <div className='flex mb-1'>
                        <Button className='w-1/2' icon='add' onClick={handleNew}>New Template</Button>
                    </div>
                }
                <InputGroup leftIcon="search" placeholder="Search..." onChange={(e) => { load(e); }} style={{ marginBottom: '20px',display:'none' }} />
            </div>
    
            <div className='grid grid-cols-2 gap-[4px] overflow-y-auto overflow-x-hidden'>
                {templates.map((template, index) =>
                    <Thumbnail key={template.id} src={template.thumbnail} name={getTemplateName(template)} selected={checkSelected(template)}
                        onSelect={() => handleSelect(template)}
                        onDelete={() => handleDelete(template)}
                    />)}
            </div>
    
            <ContactUsDialog
                        isOpen={isDialogOpen} 
                        onClose={handleCloseDialog} 
                        onContactUs={handleContactUs}
                        entityType={entityType}
                        limit={imageEditorLimit}
                    />
    
            <Dialog
                isOpen={isChooseTemplateDialogOpen}
                onClose={handleCloseChooseTemplateDialog}
                title="Choose your template"
                style={{ width: '55vw', height: '80vh'}}
            >
                <div className={Classes.DIALOG_BODY} style={{ padding: "1.5rem 1.5rem 0rem 1.5rem",backgroundColor: 'white', height: "74%"}}>
                    <InputGroup leftIcon="search" placeholder="Search template" onChange={handleSearchChange} style={{ marginBottom: '20px',display:'none' }} />
                    <div className='grid grid-cols-3 gap-6' style={{overflowY: 'auto', padding: "1rem 1rem 1rem 0rem", width: "100%", height: "88%"}}>
                    <div
                            className={`flex flex-col items-center justify-center border border-dashed p-4 cursor-pointer ${isCreateOwnTemplateClicked ? 'border-blue-500' : 'border-gray-300'}`}
                            style={{ background: '#F7F9FB', borderRadius: '3%', border: '1px' }}
                            onClick={handleCreateOwnTemplateClick}
                        >
                        {isCreateOwnTemplateClicked ? <CreateTemplateBlueIcon /> : <CreateTemplateIcon />}
                        <div className={`create-your-own-template ${isCreateOwnTemplateClicked ? 'text-blue-500' : ''}`}>Create your own template</div>
                        </div>
                        {templatesLibrary.map((template, index) => 
                            <div key={template.id} className='flex flex-col items-center relative'>
                                <Thumbnail 
                                    src={template.thumbnail} 
                                    name={getTemplateName(template)} 
                                    selected={selectedTemplate && selectedTemplate.id === template.id}
                                    onSelect={() => {
                                        setSelectedTemplate(template);
                                        setIsCreateOwnTemplateClicked(false);
                                    }}
                                />
                                <div className="tooltip-container absolute bottom-0 right-0 mb-2 mr-2">
                                    <TemplateInfoIcon />
                                    <span className="tooltip-text">{template.description}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button intent="primary" onClick={() => {
                            if (selectedTemplate) {
                                handleLibrarySelect(selectedTemplate);
                            }
                            if(isCreateOwnTemplateClicked){
                                handleCreateNewTemplate()
                                setIsCreateOwnTemplateClicked(false);
                            }
                            handleCloseChooseTemplateDialog();
                        }}
                        disabled={!selectedTemplate && !isCreateOwnTemplateClicked}>Select</Button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
});

export const handleSelectTemplate = async (store, template, url) => {
    const req = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            ...(globalStore.apiKey ? { "x-api-key": globalStore.apiKey } : {}),
        },
    });
    
    if (!req.ok) {
        if (req.status === 404) {
            alert("No matching template ID found for the current farm.");
            return;
        }
        throw new Error(`Error fetching template: ${req.statusText}`);
    }

    const json = await req.json();

    const pagesIds = store.toJSON().pages.map(page => page.id);
    store.deletePages(pagesIds);

    const { height, width, unit, dpi, pages } = json.content;
    for (const i in pages) {
        store.addPage(pages[i]);
    }
    store.setSize(width, height);
    store.setUnit({ unit, dpi });
    store.selectPage(pages[0]?.id);
    globalStore.template = json;
    globalStore.contentChanged = false;
    globalStore.showZoom = true;
};




