import Designer from './designer';

const DesignerPopup = ({options, callback}) => {
    document.body.style.overflow = 'hidden';

    const handleSaveImage= (data) => {
        handleClose();
        if(callback){
            callback(data);
        }
    }

    const handleClose = () => {
        if (options?.allowClose === false) {
            return;
        }
        const els = document.getElementsByTagName('te-designer-popup');
        for (const el of els) {
            el.remove();
        }
        document.body.style.overflow = 'auto';
    };
    return (
        <div
        id='te-designer'
        className="absolute top-0 left-0 w-screen h-screen flex flex-col bg-black/35 z-[1001]"
        style={{
            padding: options.fullScreen ? '0' : '2.5rem',
        }}
    >
        <div className='rounded-[5px] w-full h-full overflow-hidden'>
            <Designer options={options} onClose={handleClose} onSaveImage={handleSaveImage} />
        </div>
    </div>
    )
}

export default DesignerPopup;
