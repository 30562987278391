import React, { useState, useEffect } from 'react';
import { LinkIcon, DoneLinkIcon } from '../components/icons';
import { observer } from 'mobx-react-lite';
import { globalStore } from './globalStore';
import '../styles/generateLink.css';

export const GenerateLink = observer(({ polotnoStore }) => {
    const [iconType, setIconType] = useState(globalStore.iconType);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [allowImageApi, setAllowImageApi] = useState(null); 

    useEffect(() => {
        setIconType(globalStore.iconType);
        const fetchAllowImageApi = async () => {
            try {
                const res = await fetch(`${globalStore.serverUrl}/cloudtemplates/image-api`, {
                    headers: {
                        "Content-Type": "application/json",
                        ...(globalStore.apiKey ? { "x-api-key": globalStore.apiKey } : {}),
                    }
                });
                if (res.ok) {
                    const data = await res.json();
                    setAllowImageApi(data.allow_image_api);
                } else {
                    console.error('Failed to fetch allow_image_api');
                    setAllowImageApi(false); 
                }
            } catch (err) {
                console.error('Error fetching image API status:', err);
                setAllowImageApi(false); 
            }
        };

        fetchAllowImageApi();
    }, [globalStore.iconType]);

    const generateLink = async () => {
        const template = globalStore.template.id;
        const currentPage = polotnoStore.activePage.id;

        let url = `${globalStore.serverUrl}/cloudtemplates/link/${template}`;
        if (currentPage !== undefined) {
            url += `/${currentPage}`;
        }
        try {
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    ...(globalStore.apiKey ? { "x-api-key": globalStore.apiKey } : {}),
                }
            });

            if (res.ok) {
                const link = await res.json();
                if (navigator.clipboard) {
                    navigator.clipboard.writeText(link).then(() => {
                        setFeedbackMessage('Link copied to clipboard');
                        setIconType('done');
                        setTimeout(() => {
                            setIconType(globalStore.iconType);
                            setFeedbackMessage('');
                        }, 2000);
                    }).catch(err => {
                        setFeedbackMessage('Failed to copy link');
                        console.error('Failed to copy link: ', err);
                    });
                } else {
                    setFeedbackMessage('Clipboard not supported');
                }
            } else {
                const text = await res.text();
                setFeedbackMessage('Failed to generate link');
                console.error("Error response:", text);
            }
        } catch (err) {
            setFeedbackMessage('Fetch error');
            console.error("Fetch error:", err);
        }
    };

    const renderIcon = () => {
        switch (iconType) {
            case 'done':
                return <DoneLinkIcon />;
            default:
                return <LinkIcon />;
        }
    };
    if (allowImageApi === false) {
        return null;
    }
    return allowImageApi === null ? (
        <div>Loading...</div>
    ) : (
        <div style={{ position: 'relative', display: 'inline-block', marginLeft: "0.5rem", marginTop: "0.3rem" }}>
            <div
                className="icon-container-generate-link"
                style={{ cursor: globalStore.iconType === 'disabled' ? 'not-allowed' : 'pointer' }}
                onClick={globalStore.iconType === 'disabled' ? null : generateLink}
            >
                {renderIcon()}
                <div className="icon-tooltip-generate-link">
                    {feedbackMessage !== '' ? feedbackMessage : "Generate link"}
                </div>
            </div>
        </div>
    );
});

export default GenerateLink;