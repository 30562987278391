import React from 'react';
import { HTMLSelect } from "@blueprintjs/core";

const StyledResolutionSelect = ({ value, onChange }) => {
  const resolutions = [
    { value: '1440_810', label: '1440 X 810 (16:9)' },
    { value: '1440_1440', label: '1440 X 1440 (1:1)' },
    { value: '810_1440', label: '810 X 1440 (9:16)' },
    { value: '1152_1440', label: '1152 X 1440 (4:5)' }, 
    { value: '1440_1152', label: '1440 X 1152 (5:4)' }
  ];

  return (
    <HTMLSelect
      value={value}
      onChange={onChange}
      options={resolutions}
      fill={true}
      iconName="caret-down"
    />
  );
};

export default StyledResolutionSelect;