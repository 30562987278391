import React, { useEffect, useRef, useState } from 'react';
import { SectionTab } from 'polotno/side-panel';
import { AIGeneratorIcon } from '../components/icons'
import { observer } from 'mobx-react-lite';
import { globalStore} from "./globalStore";
import { Button, TextArea,Checkbox, Spinner, Tooltip, Dialog } from '@blueprintjs/core';
import logger from '../logger';
import '../styles/generative.css'
import  CustomRadio from '../components/customRadio';
import StyledResolutionSelect from './resolutionsSelect'

const sleep = async(ms) => {
    return new Promise((resolve, reject) => {
        setTimeout(resolve, ms);
    });
}

export const AIGenerator = observer((props)=>{

    const [aiPrompt, setAiPrompt] = useState('');
    const [imgSrc, setImgSrc] = useState();
    const [imgBase64, setImgBase64] = useState('');
    const [genType, setGenType] = useState('asset');
    const [isWorking, setIsWorking] = useState(false);
    const [aiStatus, setAiStatus] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [genAssetsForElements, setGenAssetsForElements] = useState(true);
    const [assetRes, setAssetRes] = useState('1440_810');
    // const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [radioSelectedValue, setRadioSelectedValue] = useState('asset');
    const [hasAiCredits, setHasAiCredits] = useState(true);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const PLACEHOLDER_IMAGE_URL = 'https://d2f9eheulyqk7v.cloudfront.net/661d25b7bc1712582686a214/assets/677f9772588f243458e5b831.png';

    const handleAiPrompt = (e) => {
        setAiPrompt(e.nativeEvent.target.value);
     }

    const handleGenerate = async (p) => {
        setAiStatus('');
        if(genType==='elements'){
            try{
                setIsWorking(true);
                await generateElements(p);
                setIsWorking(false);
                setAiStatus('');
                checkAiCreditsNumber();
            }catch(err){
                console.log(err);
                setIsWorking(false);
                setAiStatus('');
            }
            
        }else if(genType==='asset'){
            try{
                setIsWorking(true);
                await generateAsset(p);
                setIsWorking(false);
                setAiStatus('');
                checkAiCreditsNumber();
            }catch(err){
                console.log(err);
                setIsWorking(false);
                setAiStatus('');
            }
        }
    }
    const generateElements = async (p) => {
        setAiStatus('Generating elements');
    
        let page = props.store.activePage || (props.store.addPage() && props.store.activePage);
    
        const res = await fetch(`${globalStore.serverUrl}/cloudtemplates/generate`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                ...(globalStore.apiKey ? { "x-api-key": globalStore.apiKey } : {}),
            },
            body: JSON.stringify({
                prompt: p,
                width: page?.width === 'auto' ? props?.store?.width : page?.width,
                height: page?.height === 'auto' ? props?.store?.height : page?.height
            })
        });
    
        if (res.status === 200) {
            const data = await res.json();
            console.log(data);
    
            setAiStatus(`Generated ${data.children.length} new elements`);
    
            for (const el of data.children) {
                if (el.custom?.use_gen && el.custom?.desc && el.type === 'image' && genAssetsForElements) {
                    let w = el.width;
                    if (w < 256) { w = 256; }
                    else if (w > 1440) { w = 1440; }
    
                    let h = el.height;
                    if (h < 256) { h = 256; }
                    else if (h > 1440) { h = 1440; }
    
                    try {
                        // Attempt to generate the asset
                        const src = await generateAsset(el.custom.desc, true, w, h);
                        el.src = src 
                    } catch (error) {
                        console.error("Failed to generate asset", error);
                        el.src = PLACEHOLDER_IMAGE_URL;
                    }
                } else if (el.type === 'image' && !el.name.toLowerCase().includes('logo')) {
                    el.src = PLACEHOLDER_IMAGE_URL;
                }
    
                page.addElement(el);
            }
            logger.logAction("Template was Generated!",{action:"Generate Template",entity:"Ai Template",templateData:data})
        }
    };

    const checkAiCreditsNumber = async () => {
        try {
            const res = await fetch(`${globalStore.serverUrl}/cloudtemplates/credits`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    ...(globalStore.apiKey ? { "x-api-key": globalStore.apiKey } : {}),
                },
            });
            if(res.ok){
                const data = await res.json();
                setHasAiCredits(data?.credits > 0); 
            }
        } catch (error) {
            console.error("Error fetching AI credits:", error);
        }
    };

    const generateAsset = async (p, autoUpload, width, height) => {
        const resSetting = assetRes.split('_');
        width = width || parseInt(resSetting[0]);
        height = height || parseInt(resSetting[1]);
    
        setAiStatus('Generating new asset');
        const res = await fetch(`${globalStore.serverUrl}/cloudassets/generate`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                ...(globalStore.apiKey ? { "x-api-key": globalStore.apiKey } : {}),
            },
            body: JSON.stringify({
                prompt: p,
                width: width || 1920,
                height: height || 1080
            })
        });
    
        if (res.status === 200) {
            const resData = await res.json();
            let img = {};
            let timeoutCounter = 0;
    
            do {
                setAiStatus(`Waiting for asset`);
                if (!resData?.result?.includes('localhost')) {
                    resData.result = resData.result.replace(/^http:/, 'https:');
                }
                const resImg = await fetch(resData.result, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        ...(globalStore.apiKey ? { "x-api-key": globalStore.apiKey } : {}),
                    }
                });
                if (resImg.status === 200) {
                    img = await resImg.json();
                }
    
                await sleep(2000);
                timeoutCounter++;
            } while (img.status !== 'Ready' && timeoutCounter < 10);
    
            if (img.status === 'Ready') {
                console.log(img.result.sample);
                setImgSrc(img.result.sample);
                setImgBase64(img.result.data);
                logger.logAction("Asset was Generated!",{action:"Generate Asset",entity:"Ai asset",assetURL:img.result.sample})
    
                if (autoUpload) {
                    return await saveAsset('AI Element', img.result.data);
                }
    
                return img.result.sample;
            }
        }
        setAiStatus('Error accured - Did not found matching asset, using placeholder instead');
        await sleep(3000);
        setImgSrc(PLACEHOLDER_IMAGE_URL); // setting the placeholder for display
        return PLACEHOLDER_IMAGE_URL; // returning the placeholder image URL
    };

    function base64ToFile(base64String, fileName) {
        // Extract the Base64 part by splitting the Data URL (if it's present)
        const arr = base64String.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];  // Extract the MIME type
        const bstr = atob(arr[1]);  // Decode Base64 into binary string
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
    
        // Convert the binary string into an array of bytes
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
    
        // Create a Blob from the byte array and specify the MIME type
        const blob = new Blob([u8arr], { type: mime });
    
        // Create a File from the Blob (the second argument is the filename)
        return new File([blob], fileName, { type: mime });
    }

    const uploadAsset = async(file) => {
        setIsDialogOpen(false);
        try {
            setAiStatus(
                globalStore.currentAssetFolderName 
                    ? `Uploading asset (${file.name}) in ${globalStore.currentAssetFolderName}`
                    : `Uploading asset (${file.name})`
            );
            const url = `${globalStore.serverUrl}/cloudassets/get-presigned-url/image/?ext=${file.type.split('/')[1]}&contentType=${file.type}&filename=${file.name}`;
        
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    ...(globalStore.apiKey ? {"x-api-key": globalStore.apiKey} : {}),
                },
            });
            const { signedUrl, key, asset_id} = await response.json();
            
            const uploadResponse = await fetch(signedUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': file.type,
                },
                body: file,
            });

            if (!uploadResponse.ok) {
                throw new Error('Failed to upload file to cloud storage');
            }

            await fetch(`${globalStore.serverUrl}/cloudassets/asset/save`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...(globalStore.apiKey ? {"x-api-key": globalStore.apiKey} : {}),
                },
                body: JSON.stringify({ 
                    asset_id, 
                    key, 
                    filename: file.name, 
                    contentType: file.type,
                    currentFolder: globalStore.currentAssetFolder,
                    type: 'image',
                    aiUse : true,
                    aiPrompt : ''
                }),
            });
            //Move the asset cdn to .env
            return `https://d2f9eheulyqk7v.cloudfront.net/${key}`;
        
        } catch (error) {
            console.error('Error uploading file:', error);
            setIsWorking(false);
        }
    };

    const saveAsset = async (name, b64) =>{
        name = name || prompt("Asset Name:", 'New AI Asset');

        if(name){                
            setAiStatus(`Generating new asset`);
            const file = base64ToFile(b64 || imgBase64, `${name}`);
            const asset_url  = await uploadAsset(file);
            globalStore.refreshAssets();
            return asset_url;
        }
    }

    const handleSave = async (name, b64) =>{
        setIsWorking(true);
        try{
            const asset_url = await saveAsset(name, b64)
            logger.logAction("AI Asset was Saved",{asset_url:asset_url,action:"Add",entity:"Ai asset"})
        }catch(err){
            console.log(err);
            setIsWorking(false);
            logger.logActionError("Error accured - AI Asset was not Saved!",{asset_name:name,error: err?.message,action:"Add",entity:"Ai asset"})
        }
        setIsWorking(false);
    }

    const handleAssetsForElements = (e) =>{
        setGenAssetsForElements(e.currentTarget.checked);
    }

    useEffect(() => {
        if (imgSrc && genType === 'asset') {
            setIsDialogOpen(true);
        }
    }, [imgSrc]);

    useEffect(() => {
        if (globalStore.serverUrl) {
            checkAiCreditsNumber();
        }
    }, [globalStore.serverUrl]);
  
    return (
        <div className="ai-generator-container">
            <div className="ai-generator-content">
                <div className="content-container">
                    {/* Title Section */}
                    <div className="title-section">
                        <h3 className="header-text">AI Generator</h3>
                        <div className="ai-divider-up"></div>
                    </div>

                    {/* Radio Section */}
                    <div className="radio-section">
                        <div className="radio-group">
                            <CustomRadio 
                                label="Asset" 
                                value="asset" 
                                selectedValue={radioSelectedValue} 
                                onChange={(value) => {
                                    setRadioSelectedValue(value);
                                    setGenType(value);
                                }}
                                labelClassName="custom-radio-label"
                            />
                            <CustomRadio 
                                label="Template" 
                                value="elements" 
                                selectedValue={radioSelectedValue} 
                                onChange={(value) => {
                                    setRadioSelectedValue(value);
                                    setGenType(value);
                                }}
                                labelClassName="custom-radio-label"
                            />
                        </div>
                    </div>

                    {/* Prompt Section */}
                    <div className="prompt-section">
                        <TextArea
                            className="prompt-textarea"
                            value={aiPrompt}
                            rows={4}
                            placeholder="Type a prompt to generate content..."
                            onChange={handleAiPrompt}
                        />
                    </div>

                    {/* Aspect Ratio Section */}
                    {genType === 'asset' && (
                        <div className="aspect-ratio-container">
                            <div className="aspect-ratio-header">Aspect Ratio</div>
                            <StyledResolutionSelect 
                                className="resolution-select"
                                value={assetRes} 
                                onChange={e => setAssetRes(e.target.value)} 
                            />
                        </div>
                    )}

                    {/* Generate Assets Checkbox */}
                    {genType === 'elements' && (
                        <div className="generate-assets-checkbox">
                        <Checkbox
                            checked={genAssetsForElements}
                            label={
                            <span className='checkbox-generate-elements-label'>
                                Generate Assets
                            </span>
                            }
                            onChange={handleAssetsForElements}
                        />
                        </div>
                        
                    )}

                </div>

                <div className="ai-divider-down"></div>

                 {/* Generate Button */}
                <div className="generate-button-container">
                    <Tooltip
                        position="top"
                        content={
                            !hasAiCredits
                                ? "No credits left. Contact your Customer Success"
                                : aiPrompt.trim() === ''
                                ? "Please enter a prompt to generate content"
                                : ""
                        }
                    >
                        <Button
                            intent="primary"
                            disabled={!hasAiCredits || aiPrompt.trim() === ''}
                            className="custom-button-generate-generative"
                            onClick={() => handleGenerate(aiPrompt)}
                        >
                            Generate
                        </Button>
                    </Tooltip>
                </div>

                {/* Image Dialog */}
                {(genType === 'asset' && imgSrc) && (
                    <Dialog 
                        isOpen={isDialogOpen}
                        onClose={() => setIsDialogOpen(false)}
                        title="Generated Asset"
                        className="generated-image-dialog"
                        canEscapeKeyClose={false} // Prevent closing with Escape key
                        canOutsideClickClose={false} // Prevent closing by clicking outside 
                       >
                        <div className="generated-image-container">
                            <div className="image-wrapper">
                                <img src={imgSrc} alt={aiPrompt} />
                            </div>
                            <div className="dialog-footer">
                                <Button intent="primary" onClick={() => handleSave()}>
                                    Save Asset
                                </Button>
                            </div>
                        </div>
                    </Dialog>
                )}
    
                {/* Loading Overlay */}
                {isWorking && (
                    <div className="loading-overlay">
                        <div className="loading-popup">
                            <Spinner size={16} className="spinner-margin" intent="primary" />
                            {aiStatus && <span className="loading-status">{aiStatus}</span>}
                            {/* <span className="loading-status">Generating elements...</span> */}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
});

export const AIGeneratorSection = {
    name: 'GenAI',
    Tab: (props) => (
      <SectionTab name="GenAI" {...props}>
       <span className='bp5-icon bp5-icon-fullscreen'>
            <AIGeneratorIcon height="22px" style={{height: '22px', width: '22px'}}/>
        </span>
      </SectionTab>
    ),
    Panel: ({ store,}) => (
      <AIGenerator store={store} />
    ),
  };