import helperFunctions from "./helperFunctions";
import { globalStore} from "./components/globalStore";
import { addGlobalFont, getFontsList } from 'polotno/config';
import logger from './logger';

const uploadFont = async (fontToUpload) => {
    let contentType;
    const match = fontToUpload.url.match(/^data:(.*?);base64,/);
    if (match && match[1]) {
        contentType = match[1];
    }
    const ext ='otf'
    globalStore.fonts.push(fontToUpload);
    try {
        const response = await fetch(`${globalStore.serverUrl}/cloudassets/get-presigned-url/font?ext=${ext}&contentType=${contentType}&filename=${fontToUpload.fontFamily}`, {
            method: 'GET',
            headers: {
                ...(globalStore.apiKey ? { "x-api-key": globalStore.apiKey } : {}),
            },
        });

        const { signedUrl, key, asset_id } = await response.json();

        const blob = helperFunctions.dataURLtoBlob(fontToUpload.url);
        const uploadResponse = await fetch(signedUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': `${contentType}`,
            },
            body: blob,
        });

        if (!uploadResponse.ok) {
            throw new Error('Failed to upload file to cloud storage');
        }
        await fetch(`${globalStore.serverUrl}/cloudassets/asset/save`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...(globalStore.apiKey ? { "x-api-key": globalStore.apiKey } : {}),
            },
            body: JSON.stringify({
                asset_id,
                key,
                filename: fontToUpload.fontFamily,
                contentType: contentType,
                currentFolder: '',
                type: 'font'
            }),
        });
        addGlobalFont({
            fontFamily: fontToUpload.fontFamily,
            url: fontToUpload.url
        });
        
        logger.logAction("Font was uploaded",{font: fontToUpload.fontFamily, asset_id:asset_id,action:"Add",entity:"font"})

    } catch (error) {
        globalStore.fonts = globalStore.fonts.filter(font => font.fontFamily !== fontToUpload?.fontFamily);
        console.error('Error uploading font:', error);
        logger.logActionError("Error accured - Font was not uploaded!",{font: fontToUpload.fontFamily, asset_id:asset_id,action:"Add",entity:"font"})
    }
};

const deleteFont = async (fontToDelete) => {
    if (fontToDelete?.fontFamily) {
        globalStore.fonts = globalStore.fonts.filter(font => font.fontFamily !== fontToDelete?.fontFamily);
        try {
            await fetch(`${globalStore.serverUrl}/cloudassets/font/${fontToDelete?.fontFamily}`, {
                method: 'DELETE',
                headers: {
                    ...(globalStore.apiKey ? { "x-api-key": globalStore.apiKey } : {}),
                },
            });
            logger.logAction("Font was deleted", { font: fontToDelete?.fontFamily,action:"Delete",entity:"font" });
        } catch (error) {
            globalStore.fonts.push(fontToDelete);
            console.error("Failed to delete font:", error);
            logger.logActionError("Failed to delete font", { font: fontToDelete?.fontFamily, error: error?.message,action:"Delete",entity:"font" });
        }
    }
};

const manageFonts = async (fonts) => {
    if(globalStore.fontsInProgress) {
        return;
    }
    if(globalStore?.fonts?.length < fonts?.length) {
        await uploadFont(fonts[fonts.length - 1]);
    } else if (globalStore?.fonts?.length > fonts?.length) {
        const fontToDelete = globalStore.fonts.find(globalFont => !fonts.find(font => font.fontFamily === globalFont.fontFamily));
        await deleteFont(fontToDelete);
    }
}

const setFontsGlobally = async () => {
    try {
        const queryParams = new URLSearchParams();
        queryParams.append('type', 'font');
        
        const response = await fetch(`${globalStore.serverUrl}/cloudassets/${''}?${queryParams.toString()}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                ...(globalStore.apiKey ? { "x-api-key": globalStore.apiKey } : {}),
            }
        });
        
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const fonts = data.assets.map(asset => {
            return {
                fontFamily: asset.name,
                url: asset.url
            }  
        });
        globalStore.fonts = fonts;
        globalStore.fontsInProgress = true;
        return fonts;
                
    } catch (error) {
        console.error('Error loading fonts:', error);
    }
};


export default {
    manageFonts,
    setFontsGlobally,
};
