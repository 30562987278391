import { observable, action } from 'mobx';

export const globalStore = observable({
    assetsRefreshCounter: 0,
    currentAssetFolder: '',
    currentAssetFolderName: '',
    setCurrentAssetFolder: action(function(folder, folderName = '') {
        this.currentAssetFolder = folder;
        this.currentAssetFolderName = folderName;
    }),

    refreshAssets: action(function() {
        this.assetsRefreshCounter += 1;
    })
});